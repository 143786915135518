<template>
    <div v-if="user" class="container-lg mt-3 mt-md-5 mb-3 mb-md-5">
        <h4>Public Posts by <strong>{{ user.name }}</strong></h4>
        <div class="row g-3">
            <div class="col-lg-3">
                <!-- Profile image -->
                <div class="profile-img-card mb-3">
                    <div class="profile-img-card-header"></div>
                    <div class="profile-img text-center">
                        <b-avatar :text="getFirstLetters(user.name)" size="5rem"></b-avatar>
                        <span class="user-name">{{ user.name }}</span>
                        <ul>
                            <li><strong>{{ surveys.data.length }}</strong> {{ surveys.data.length > 1 ? 'posts' : 'post' }}</li>
                            <li v-if="user.location"><font-awesome-icon icon="map-marker-alt" />{{ user.location }}</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-9">
                <div class="survey-list-container">
                    <!-- Spinner to display while posts are loading -->
                    <div v-if="wait" class="list-spinner d-flex justify-content-center mb-3">
                        <b-spinner label="Loading..."></b-spinner>
                    </div>

                    <!-- Empty store shows if there's an error -->
                    <div v-if="!wait && error" class="posts-empty">
                        <div class="posts-empty-inner">
                            <div class="posts-empty-inner_img">
                                <img loading="lazy" src="@/assets/img/error-message.png" width="80" height="80"
                                     alt="Empty box"/>
                            </div>
                            <h4>{{ error }}</h4>
                            <h6>An error occurred while trying to fetch your posts.</h6>
                        </div>
                    </div>

                    <!-- List of posts -->
                    <ul class="survey-list">
                        <!-- Survey list item -->
                        <li v-for="survey in surveys.data" :key="survey.id" class="survey-list-item">
                            <h6>
                                <router-link :to="`/surveys/${survey.code}/${survey.slug}`">
                                    <font-awesome-icon icon="clipboard-list" />
                                    {{ survey.title }}
                                </router-link>
                            </h6>
                            <p class="excerpt">
                                {{ survey.shortDescription }}
                            </p>
                            <div class="categories mb-3">
                                <b-badge v-for="c in survey.categories" :key="c.id">
                                    {{ c.name }}
                                </b-badge>
                            </div>
                            <p v-if="survey.tags" class="tags">
                                <span class="text-muted">Tags:</span> {{ survey.tags }}
                            </p>
                            <div class="survey-user ml-3">
                                <span class="datestamp">
                                    {{ moment(survey.created_at).format("MMM D, YYYY HH:mm") }}
                                </span>
                            </div>
                            <div class="survey-list-item-actions">
                                <ul>
                                    <li>
                                        <span class="large-number">{{ survey.views }}</span>
                                        <span class="small-desc">views</span>
                                    </li>
                                    <li>
                                        <router-link :to="`/surveys/${survey.code}/${survey.slug}`"
                                                     class="text-decoration-none">
                                            <span class="large-number">{{ survey.questions_count }}</span>
                                            <span class="small-desc">questions</span>
                                        </router-link>
                                    </li>
                                    <li>
                                        <span class="large-number">{{ survey.respondents_count }}</span>
                                        <span class="small-desc">responses</span>
                                    </li>

                                    <!-- Dropdown menu -->
                                    <li>
                                        <b-dropdown right no-caret variant="link" :id="`dropdown-${survey.code}`"
                                                    toggle-class="text-decoration-none"
                                                    class="survey-list-item-options">
                                            <template #button-content>
                                                <font-awesome-icon icon="chevron-down" />
                                            </template>
                                            <b-dropdown-item>
                                                <router-link
                                                    :to="'/surveys/'+ survey.code +'/'+ survey.slug"
                                                    title="Owner has disabled multiple responses from the same user">
                                                    Start survey
                                                </router-link>
                                            </b-dropdown-item>
                                            <b-dropdown-item>
                                                <router-link 
                                                    :to="`/surveys/ratesurvey/${survey.code}`">Rate this survey
                                                </router-link>
                                                </b-dropdown-item>
                                            <b-dropdown-divider></b-dropdown-divider>
                                            <b-dropdown-item>
                                                <router-link :to="`/surveys/flag/${survey.code}`">
                                                    Flag as inappropriate
                                                </router-link>
                                            </b-dropdown-item>
                                        </b-dropdown>
                                    </li>

                                </ul>
                            </div><!-- End actions/counts -->
                        </li><!-- End survey list item -->
                    </ul>
                </div><!-- End survey list container -->


                <!-- Pagination -->
                <Pagination
                    :limit="1"
                    :showDisabled="true"
                    :data="surveys"
                    @pagination-change-page="loadSurveys">
                </Pagination>

            </div>
        </div>
    </div>
</template>

<script>

import Pagination from 'laravel-vue-pagination'

export default {
    name: "UserProfile",
    components: {Pagination},
    props: ['id'],
    data() {
        return {
            user: null,
            surveys: {data: []},
        }
    },
    mounted() {
        this.$http.get('api/user/' + this.id).then(response => {
            this.user = response.data.data
        })
        this.loadSurveys()
    },
    methods: {
        getFirstLetters(str) {
            const firstLetters = str
                .split(' ')
                .map(word => word[0])
                .join('');

            return firstLetters;
        },        
        loadSurveys(page = 1) {
            this.$http.get('api/user/' + this.id + '/posts?page=' + page).then(response => {
                this.surveys = response.data.data
            })
        }
    }
}
</script>

<style scoped>

</style>